<template>
  <!-- <c-step
    stepperGrpCd="HEA_CHECKUP_STEP_CD"
    :currentStepCd="popupParam.stepCd"
    :param="popupParam"
    v-model="popupParam.stepCd"
    @currentStep="currentStep"
    >
  </c-step> -->
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <components
          :is="tab.component"
          :popupParam="popupParam"
          :checkUp.sync="checkUp"
          @changeStatus="changeStatus"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checkup-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'plan',
      tabItems: [
        { name: 'plan', icon: 'edit', label: '기본정보', component: () => import(`${'./checkUpPlan.vue'}`) },
      ],
      addTabItems: [
        { name: 'result', icon: 'assignment', label: '건강검진 결과', component: () => import(`${'./checkUpResult.vue'}`) },
        { name: 'opinion', icon: 'sick', label: '검진 소견서', component: () => import(`${'./checkUpOpinion.vue'}`) },
      ],
      checkUp: {
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        uploadFlag: 'N',
        checkupStartDate: '',
        checkupEndDate: '',
        checkupDateArray: [],
        regUserId: '',
        chgUserId: '',

        hospitalId: null,
        hospitalName: '',
        hospitalLocation: '',
        typeFlag: 'Y',
      },
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 20);
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.hea.checkup.plan.get.url;
      if (this.popupParam.heaCheckupPlanId) {
          this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.heaCheckupPlanId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.heaCheckupPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.checkUp = this.$_.clone(_result.data);
          if (this.checkUp.checkupStartDate && this.checkUp.checkupEndDate) {
            this.checkUp.checkupDateArray = [this.checkUp.checkupStartDate, this.checkUp.checkupEndDate];
          }
        },);
      } 
    },
    changeStatus(_heaCheckupPlanId) {
      this.popupParam.heaCheckupPlanId = _heaCheckupPlanId;
      this.tabItems = this.tabItems.concat(this.addTabItems);
      this.getDetail();
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>